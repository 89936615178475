import { selectedSpaceAtom } from "@/atoms/spaces"
import Avatar from "@/components/avatar"
import { SIDE_BAR_WIDTH } from "@/components/timeline/helpers/constants"
import { IUser } from "@/features/people/types"
import { DateType, IDate } from "@/types/glossary"
import {
  ImageWithFallback,
  ORGANISATION_AVATERS,
} from "@/utils/ImageWithFallback"
import { parseName } from "@/utils/helpers"
import {
  faEdit,
  faMoneyBillTransfer,
  faStickyNote,
  faTreePalm,
  faUserPlus,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import {
  faBell,
  faDoorOpen,
  faRotate,
  faStars,
  faUserClock,
  faUserXmark,
} from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Tooltip } from "antd"
import dayjs, { Dayjs } from "dayjs"
import { isEmpty } from "lodash"
import Link from "next/link"
import { useEffect, useRef, useState } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { TTimelineUserTask } from "../types"
import {
  candidateFlowAtom,
  selectedTalentAtom,
  selectedTaskAtom,
  selectedUserAtom,
  timelineAlertAtom,
  timelineNotesAtom,
} from "../utils/atoms"
import { calculateAllocations } from "../utils/builders"
import { OoOTypes } from "./ModalOutOfOffice"
import { PeriodElements } from "./PeriodElements"

interface IAllocationElement {
  periods: IDate[]
  talents?: Record<string, any>[]
  task?: string
  start: Date
  end: Date
}

export const AllocationPill = ({
  allocation,
  status,
}: {
  allocation: number
  status: string
}) => {
  let backgroundColor = "#E5EBF2"
  let color = "#79879B"
  let icon = faRotate
  switch (status) {
    case "hired": {
      backgroundColor = "#0ADE90"
      color = "#ffffff"
      icon = faStars
      break
    }
    case "potential": {
      backgroundColor = "#60a5fa"
      color = "#ffffff"
      icon = faUserClock
      break
    }
  }

  if (allocation === 0) {
    return null
  }

  return (
    <div
      style={{ backgroundColor, color }}
      className={
        "flex w-20 items-center justify-center gap-2 rounded-md px-2 py-1 text-xs"
      }
    >
      <FontAwesomeIcon icon={icon} />
      <span>{allocation}%</span>
    </div>
  )
}

export const AllocationElement = ({
  periods,
  talents,
  start,
  end,
}: IAllocationElement) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const allocation = calculateAllocations(talents ?? [], start, end)
  const totalAllocation =
    allocation.hired + allocation.potential + allocation.extension
  const [width, setWidth] = useState<number>(0)

  useEffect(() => {
    const div = containerRef.current
    if (!div) return

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setWidth(entry.contentRect.width)
      }
    })

    resizeObserver.observe(div)

    return () => {
      if (div) {
        resizeObserver.unobserve(div)
      }
    }
  }, [])

  const getPeriods = (type: DateType) => {
    return periods?.filter((d) => {
      if (d.type !== type) return false
      if (!d || !d?.start || !d?.end) return false
      const isAfterStart =
        dayjs(d.start).isAfter(dayjs(end)) || dayjs(d.end).isSame(dayjs(start))
      const isBeforeEnd =
        dayjs(d.end).isBefore(dayjs(start)) || dayjs(d.start).isSame(dayjs(end))
      return !isAfterStart && !isBeforeEnd
    })
  }

  let bgColor = "#f87171"
  if (allocation.extension > 0) {
    bgColor = "#E5EBF2"
  }
  if (allocation.potential > 0) {
    bgColor = "#60a5fa"
  }
  if (allocation.hired > 0) {
    bgColor = "#0ADE90"
  }

  const prioritizedAllocation = Object.entries(allocation).reduce(
    (acc, [key, value]) => {
      let temp = { ...acc }
      if (value > 0) {
        if (key === "hired") {
          temp[key] = value
        } else if (Object.keys(temp)[0] !== "hired" && key === "potential") {
          temp[key] = value
        } else if (!Object.keys(temp)[0]) {
          temp[key] = value
        }
      }
      acc = temp
      return acc
    },
    {},
  )

  const [key, value] = Object.entries(prioritizedAllocation)[0] ?? []

  const priceAdjustmentDates = getPeriods("PRICE_ADJUSTMENT")

  return (
    <div
      ref={containerRef}
      className="pointer-events-auto flex h-full cursor-default flex-col justify-end overflow-hidden border-r"
    >
      <div
        style={{ backgroundColor: bgColor }}
        className={
          "relative flex h-full w-full items-center justify-center gap-2"
        }
      >
        {totalAllocation === 0 ? (
          <div
            className={
              "flex w-20 items-center justify-center gap-2 rounded-md bg-red-400 px-2 py-1 text-xs text-white"
            }
          >
            <FontAwesomeIcon icon={faUserXmark} />
            <span>0%</span>
          </div>
        ) : width < 225 ? (
          <AllocationPill status={key} allocation={value ? Number(value) : 0} />
        ) : (
          Object.entries(allocation).map(([key, value]) => {
            const Pill = (
              <AllocationPill key={key} status={key} allocation={value} />
            )
            if (width < 150) {
              if (key === "hired" && value > 0) {
                return Pill
              } else if (key === "potential" && value > 0) {
                return Pill
              } else if (key === "extension" && value > 0) {
                return Pill
              }
              return null
            } else {
              return Pill
            }
          })
        )}

        {OoOTypes.map((type) => {
          const dates = getPeriods(type)
          if (dates.length === 0) return null
          let icon = faDoorOpen
          switch (type) {
            case "HOLIDAY_PERIOD":
              icon = faTreePalm
              break
            case "OTHER_PERIOD":
              icon = faDoorOpen
              break
            default:
              break
          }

          if (dates?.length > 0) {
            const OoOPercentage = dates
              .filter((d) => d?.data?.allocation > 0)
              .map((d) => d.data.allocation)
              .reduce((a, b) => a + b, 0)
            return (
              <Tooltip
                key={type.toLowerCase()}
                title={dates.map((d) =>
                  d.start
                    ? `${dayjs(d.start).format("DD MMM YY")} - ${d.end ? dayjs(d.end).format("DD MMM YY") : "Future"}`
                    : "",
                )}
              >
                <div
                  className={
                    "flex place-content-center items-center gap-2 rounded-lg bg-gray-200 px-3 py-1 text-xs text-gray-500"
                  }
                >
                  <FontAwesomeIcon icon={icon} />
                  {`${OoOPercentage}%`}
                </div>
              </Tooltip>
            )
          }
          return null
        })}
        {priceAdjustmentDates.length > 0 && (
          <Tooltip
            title={priceAdjustmentDates.map((d) =>
              d.start
                ? `${dayjs(d.start).format("DD MMM YY")} - ${d.end ? dayjs(d.end).format("DD MMM YY") : "Future"}`
                : "",
            )}
          >
            <div
              className={
                "grid place-content-center rounded-xl bg-red-400 px-3 py-2 text-white"
              }
            >
              <FontAwesomeIcon icon={faMoneyBillTransfer} />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export const UserTitle = ({
  user,
  talent,
}: {
  user: IUser
  talent?: string
}) => {
  const setSelectedTalent = useSetRecoilState(selectedTalentAtom)
  const setTimelineNotes = useSetRecoilState(timelineNotesAtom)

  return (
    <div className="group flex w-full min-w-[18rem] items-center justify-between gap-2">
      <div className="flex flex-1 items-center gap-2">
        <Avatar email={user?.email} size={32} border={false} />
        <Link
          href={`/people/${user?.uid}`}
          onClick={(e) => e.stopPropagation()}
        >
          <p className="text-sm font-medium text-gray-500 hover:underline">
            {parseName(user)}
          </p>
        </Link>
      </div>
      <button
        onClick={(e) => {
          e.stopPropagation()
          setTimelineNotes({ open: true, user: user.uid })
        }}
      >
        <FontAwesomeIcon icon={faStickyNote} />
      </button>
      {!!talent && (
        <button
          className="scale-0 transition-all group-hover:scale-100"
          onClick={(e) => {
            e.stopPropagation()
            setSelectedTalent({ talent })
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
      )}
    </div>
  )
}

export const TaskTitle = ({
  task,
  talent,
}: {
  task?: TTimelineUserTask
  talent?: string
}) => {
  const setSelectedTalent = useSetRecoilState(selectedTalentAtom)
  const setCandidateFlow = useSetRecoilState(candidateFlowAtom)
  const space = useRecoilValue(selectedSpaceAtom)
  const isOwner = space.uid === task?._owner?.uid
  const taskUID =
    isOwner && task?.is_minibid && task?._source_task
      ? task?._source_task
      : task?.uid
  const taskName = !isEmpty(task?._name) ? task._name : "No task name found"

  return (
    <div className="group flex w-full items-center justify-between gap-2">
      <div className="flex flex-1 items-center gap-2">
        <div className="w-fit">
          <ImageWithFallback
            src={ORGANISATION_AVATERS(task?._customer)}
            width={0}
            height={0}
            alt="Customer"
            className="max-h-[32px] w-auto min-w-[32px] max-w-[60px]"
          />
        </div>
        <Link
          href={`/projects/${taskUID}?tab=board`}
          className="text-xs font-medium hover:underline"
        >
          <p title={taskName} className="line-clamp-1">
            {taskName}
          </p>
        </Link>
      </div>
      <div className="flex items-center gap-2">
        <button
          className="scale-0 transition-all group-hover:scale-100"
          onClick={(e) => {
            e.stopPropagation()
            setCandidateFlow({ task: taskUID, open: true })
          }}
        >
          <FontAwesomeIcon icon={faUserPlus} />
        </button>
        {!!talent && (
          <button
            className="scale-0 transition-all group-hover:scale-100"
            onClick={(e) => {
              e.stopPropagation()
              setSelectedTalent({ talent })
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        )}
      </div>
    </div>
  )
}

export const ImportantAlertsTitle = ({
  users,
  tasks,
}: {
  users?: string[]
  tasks?: string[]
}) => {
  const setAlertData = useSetRecoilState(timelineAlertAtom)

  return (
    <div className="group flex h-full w-full items-center justify-between gap-2 text-gray-500">
      <div className="flex items-center gap-2">
        <FontAwesomeIcon icon={faBell} size="lg" />
        <p className="font-medium">Important alerts</p>
      </div>
      <button
        className="scale-0 transition-all group-hover:scale-100"
        onClick={() => setAlertData({ users, tasks, open: true })}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
    </div>
  )
}

export const PriceAdjustmentTitle = ({ task }: { task: string }) => {
  const setSelectedTask = useSetRecoilState(selectedTaskAtom)

  return (
    <div className="group flex h-full w-full items-center justify-between gap-2 text-gray-500">
      <div className="flex items-center gap-2">
        <FontAwesomeIcon icon={faMoneyBillTransfer} size="lg" />
        <p className="font-medium">Price adjustments</p>
      </div>
      <button
        className="scale-0 transition-all group-hover:scale-100"
        onClick={() => setSelectedTask(task)}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
    </div>
  )
}

export const PeriodElement = (props: {
  start: Dayjs | null
  end: Dayjs | null
  top?: number | null
  period: IDate
  talent?: string
  users?: string[]
  tasks?: string[]
}) => {
  const { start, period } = props
  let title, icon
  if (!start) {
    return (
      <ElementInvalidDates
        talent={props?.talent}
        users={props?.users}
        tasks={props?.tasks}
      />
    )
  }
  switch (period?.data?.status) {
    case "hired": {
      icon = "fasStars"
      break
    }
    case "potential": {
      icon = "fasUserClock"
      break
    }
    case "extension": {
      icon = "fasRotate"
      break
    }
  }
  const Element = PeriodElements[props?.period?.type]
  if (!Element) return null
  return <Element {...props} icon={icon} />
}

const ElementInvalidDates = ({
  talent,
  users,
  tasks,
}: {
  talent?: string
  users?: string[]
  tasks?: string[]
}) => {
  const setSelectedTalent = useSetRecoilState(selectedTalentAtom)
  const setSelectedUser = useSetRecoilState(selectedUserAtom)
  const setSelectedTask = useSetRecoilState(selectedTaskAtom)
  const setAlertData = useSetRecoilState(timelineAlertAtom)

  const onClick = () => {
    if (!!talent) {
      setSelectedTalent({ talent })
    }
    if (users?.length === 1) {
      setSelectedUser({ user: users[0] })
      setAlertData({ open: true, users, tasks })
    }
    if (tasks?.length === 1) {
      setSelectedTask(tasks[0])
      setAlertData({ open: true, users, tasks })
    }
  }

  const getText = () => {
    if (!!talent || !!users) {
      return "No dates found"
    }
    if (!!tasks) {
      return "No price adjustment found"
    }
  }

  return (
    <div className="pointer-events-auto flex h-full items-center">
      <div
        onClick={onClick}
        className="relative z-20 flex-grow cursor-pointer border-y border-dashed border-gray-500 bg-gray-100 px-2 py-1 opacity-30"
      >
        <div
          style={{ left: SIDE_BAR_WIDTH + 56 }}
          className="sticky w-fit select-none truncate pl-2 text-sm font-medium"
        >
          {getText()}
        </div>
      </div>
    </div>
  )
}
